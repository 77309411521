import { CodeEditor } from '@/components/code-editor'
import { useAtomSelect } from '@/hooks/atom'
import { functionCallBpaList } from '@/services/api-service'
import { Collapse, Icon, Select } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import _ from 'lodash'
import React, { FC, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { bpaConfigAtom } from '../../atoms'
import { BpaActionFunctionCall, FunctionCallBpaFn } from '../../schemas'

type Props = {
  action: BpaActionFunctionCall
  onChange: (data: Partial<BpaActionFunctionCall>) => void
}

export const InputFunctionCall: FC<Props> = ({ action, onChange }) => {
  const functionCalls = useQuery({
    ...functionCallBpaList.list<FunctionCallBpaFn>(),
    refetchOnMount: false
  })
  const mode = useAtomSelect(bpaConfigAtom, 'mode')
  const scheduled = useAtomSelect(bpaConfigAtom, 'schedule_rules.scheduled_for')
  const onChangeDebounced = useDebouncedCallback(onChange, 250)
  const [selectedFn, setSelectedFn] = useState<string | null>(null)

  const optionsList = useMemo(() => {
    const _mode = scheduled ? 'scheduled' : mode
    const groups = _.groupBy(
      (functionCalls.data?.items || []).filter((item) => item.modes.includes(_mode)),
      'group'
    )

    return Object.keys(groups).map((group_str) => ({
      key: group_str,
      label: group_str,
      options:
        groups[group_str]?.map((func) => ({
          value: func.path,
          label: func.display_name
        })) || []
    }))
  }, [functionCalls, scheduled, mode])

  const fnInfo = useMemo(
    () => functionCalls?.data?.items.find((fn) => fn.path === (selectedFn || action.function))?.info,
    [functionCalls.data, selectedFn, action.function]
  )

  const onFunctionSelect = (fn: string) => {
    onChange({ function: fn })
    setSelectedFn(fn)
  }

  return (
    <div className="flex flex-col">
      <Form.Item label="Function Module" tooltip="Function module to call" className="flex-grow mb-6">
        <Select defaultValue={action.function} options={optionsList} onChange={(value) => onFunctionSelect(value)} />
      </Form.Item>
      {fnInfo ? (
        <Collapse
          bordered={false}
          size="small"
          className="mb-8"
          items={[
            {
              key: 'function-help',
              label: (
                <div className="flex flex-row items-center gap-10">
                  <Icon name="fa:circle-info" />
                  Help
                </div>
              ),
              children: <p className="whitespace-break-spaces">{fnInfo}</p>
            }
          ]}
        />
      ) : null}
      <Form.Item label="Keyword Arguments">
        <CodeEditor
          minLines={5}
          maxLines={25}
          defaultValue={action.kwargs || ''}
          onChange={(value) => onChangeDebounced({ kwargs: value })}
          mode="yaml"
        />
      </Form.Item>
    </div>
  )
}
export const InputFunctionCallMemo = React.memo(InputFunctionCall)
