import { QueryFieldSchema, QueryFilterSchema } from '@/ui/data/query-builder'
import { z } from 'zod'
import { BpaVariableTypeSchema } from './variables'

/**
 * Schemas
 */

export const BpaActionTypeSchema = z.enum([
  'set_variable',
  'set_ticket_field',
  'set_ticket_property',
  'set_hidden_reference',
  'copy_ticket',
  'sync_line_item',
  'add_line_item',
  'update_line_item',
  'remove_line_item',
  'reorder_line_items',
  'sync_team_member',
  'add_team_member',
  'remove_team_member',
  'sync_custom_record',
  'sync_from_intake_ticket',
  'send_custom_email',
  'email_customer_signature',
  'email_vendor_access',
  'send_email',
  'sync_http',
  'async_http',
  'function_call',
  'add_ticket_subscriber',
  'remove_ticket_subscriber',
  'sync_ticket_subscribers',
  'submit_to_accounting',
  'attach_printed_ticket',
  'etl_extract_data',
  'etl_transform_data',
  'etl_load_data',
  'etl_clickhouse_command'
])

export const BpaActionBaseSchema = z.object({
  uuid: z.string(),
  key: z.string(),
  data: z.object({})
})

// 'set_variable'
export const BpaActionSetVariableSchema = BpaActionBaseSchema.extend({
  type: z.literal('set_variable'),
  name: z.string(),
  d_type: BpaVariableTypeSchema,
  value: z.string(),
  asText: z.boolean().optional()
})

// set_ticket_field
export const BpaActionSetTicketFieldSchema = BpaActionBaseSchema.extend({
  type: z.literal('set_ticket_field'),
  field: z.string(),
  value: z.string().optional().nullable(),
  searchText: z.string().optional().nullable(),
  setNull: z.boolean().optional(),
  asText: z.boolean().optional()
})

// set_ticket_property
export const BpaActionSetTicketPropertySchema = BpaActionBaseSchema.extend({
  type: z.literal('set_ticket_property'),
  field: z.string(),
  value: z.string().optional().nullable(),
  setNull: z.boolean()
})

// set_ticket_property
export const BpaActionSetHiddenReferenceSchema = BpaActionBaseSchema.extend({
  type: z.literal('set_hidden_reference'),
  field: z.string(),
  value: z.string().optional().nullable(),
  setNull: z.boolean(),
  searchText: z.string().optional().nullable()
})

// copy_ticket
export const BpaActionCopyTicketSchema = BpaActionBaseSchema.extend({
  type: z.literal('copy_ticket'),
  data: z.object({
    copies: z.number().nullish(),
    copy_header: z.boolean().nullish(),
    copy_properties: z.boolean().nullish(),
    copy_internal_properties: z.boolean().nullish(),
    copy_line_items: z.boolean().nullish(),
    copy_members: z.boolean().nullish(),
    copy_attachments: z.boolean().nullish(),
    copy_metadata: z.boolean().nullish(),
    field_overrides_yaml: z.string().nullish(),
    property_overrides_yaml: z.string().nullish()
  })
})

// sync_line_item
export const BpaActionSyncLineItemSchema = BpaActionBaseSchema.extend({
  type: z.literal('sync_line_item'),
  category: z.union([z.string(), z.number()]).optional().nullable(),
  categoryText: z.string().optional().nullable(),
  equipment: z.union([z.string(), z.number()]).optional().nullable(),
  equipmentText: z.string().optional().nullable(),
  asText: z.boolean().optional(),
  values: z.array(z.string().nullable().optional().array()).optional().nullable()
})

export const BpaActionReOrderLineItemsSchema = BpaActionBaseSchema.extend({
  type: z.literal('reorder_line_items'),
  order_by: z.array(
    z.object({
      field: z.string(),
      direction: z.enum(['asc', 'desc']),
      nulls_last: z.boolean()
    })
  ),
  categories: z.array(z.number()).optional(),
  filter_type: z.literal('O').or(z.literal('E')).optional(),
  filter_by_cat_enabled: z.boolean().optional()
})

// add_line_item (extends sync_line_item)
export const BpaActionAddLineItemSchema = BpaActionSyncLineItemSchema.extend({
  type: z.literal('add_line_item'),
  number_of_lis: z.string().optional()
})

// update_line_item
export const BpaActionUpdateLineItemSchema = BpaActionBaseSchema.extend({
  type: z.literal('update_line_item'),
  q_filter: z.string(),
  include_subcomponents: z.boolean().nullish(),
  values: z.array(z.string(), z.string())
})

// remove_line_item
export const BpaActionRemoveLineItemSchema = BpaActionBaseSchema.extend({
  type: z.literal('remove_line_item'),
  q_filter: z.string()
})

// sync_team_member
export const BpaActionSyncTeamMemberSchema = BpaActionBaseSchema.extend({
  type: z.literal('sync_team_member'),
  source: z.enum(['contact', 'equipment']),
  value: z.string(),
  asText: z.boolean().optional() // todo: add ux for `Select`
})

// sync_ticket_subscribers
export const BpaActionSyncTicketSubscribersSchema = BpaActionSyncTeamMemberSchema.extend({
  type: z.literal('sync_ticket_subscribers')
})

// add_team_member
export const BpaActionAddTeamMemberSchema = BpaActionBaseSchema.extend({
  type: z.literal('add_team_member'),
  contact: z.union([z.string(), z.number()]).optional(), // leave for backward compatibility
  contactText: z.string(),
  // multiple mode. can be array of numbers, comma separated ids or placeholder like 'P[key]'
  contacts: z.union([z.array(z.number()), z.string()]).optional(),
  filter_type: z.union([z.literal('O'), z.literal('E'), z.literal('A')]),
  asText: z.boolean().optional() // todo: add ux for `Input`
})

// add_ticket_subscriber
export const BpaActionAddTicketSubscriberSchema = BpaActionAddTeamMemberSchema.extend({
  type: z.literal('add_ticket_subscriber')
})

// remove_team_member (extends add_team_member)
export const BpaActionRemoveTeamMemberSchema = BpaActionAddTeamMemberSchema.extend({
  type: z.literal('remove_team_member')
})

// remove_ticket_subscriber
export const BpaActionRemoveTicketSubscriberSchema = BpaActionRemoveTeamMemberSchema.extend({
  type: z.literal('remove_ticket_subscriber')
})

export const BpaActionSyncCustomRecordSchema = BpaActionBaseSchema.extend({
  type: z.literal('sync_custom_record'),
  data: z.object({
    label: z.string(),
    char_1: z.string(),
    char_2: z.string().nullish(),
    char_3: z.string().nullish(),
    char_4: z.string().nullish(),
    data: z.string().nullish()
  }),
  unique_by: z.array(z.string())
})

export const BpaActionSyncFromIntakeTicketSchema = BpaActionBaseSchema.extend({
  type: z.literal('sync_from_intake_ticket'),
  q_filter: z.string(),
  order_by: z.string().nullish(),
  values: z.string()
})

// send_custom_email
export const BpaActionSendCustomEmailSchema = BpaActionBaseSchema.extend({
  type: z.literal('send_custom_email'),
  custom_email: z.string(),
  customEmailText: z.string(),
  sender: z.string(),
  senderText: z.string(),
  recipients: z.string(),
  cc_emails: z.string(),
  asText: z.boolean()
})

// email_customer_signature
export const BpaActionEmailCustomerSignatureSchema = BpaActionBaseSchema.extend({
  type: z.literal('email_customer_signature'),
  contact_ids: z.string(),
  property_id: z.number().nullable(),
  property_name: z.string(),
  asText: z.boolean(),
  _selectedContacts: z.array(
    z.object({
      value: z.number(),
      label: z.string()
    })
  )
})

// email_vendor_access
export const BpaActionEmailVendorAccessSchema = BpaActionEmailCustomerSignatureSchema.extend({
  type: z.literal('email_vendor_access'),
  vendor_mail_message: z.string().nullable()
})

// send_email
export const BpaActionSendEmailSchema = BpaActionBaseSchema.extend({
  type: z.literal('send_email'),
  data: z.object({
    template_id: z.string(),
    send_to: z.string(),
    subject: z.string(),
    substitutions: z.string()
  })
})

// submit_to_accounting
export const BpaActionSubmitToAccountingSchema = BpaActionBaseSchema.extend({
  type: z.literal('submit_to_accounting'),
  service: z.string()
})

// attach_printed_ticket
export const BpaActionAttachPrintedTicketSchema = BpaActionBaseSchema.extend({
  type: z.literal('attach_printed_ticket'),
  file_format: z.enum(['pdf', 'html']),
  custom_name: z.string().nullish(),
  stylesheets: z.array(z.string()),
  include_attachments: z.boolean().nullish(),
  line_item_attachments: z.boolean().nullish(),
  show_internal_use_only: z.boolean().nullish()
})

// sync_http
export const BpaActionSyncHttpSchema = BpaActionBaseSchema.extend({
  type: z.literal('sync_http'),
  data: z.object({
    method: z.enum(['GET', 'POST', 'PUT', 'PATCH', 'DELETE']),
    url: z.string(),
    headers: z.string(),
    content: z.string(),
    resp_type: z.enum(['str', 'json']),
    resp_var: z.string().optional()
  })
})

// async_http (extends sync_http)
export const BpaActionAsyncHttpSchema = BpaActionSyncHttpSchema.extend({
  type: z.literal('async_http')
})

// function_call
export const BpaActionFunctionCallSchema = BpaActionBaseSchema.extend({
  type: z.literal('function_call'),
  function: z.string(),
  kwargs: z.string()
})

// etl_run_data_model
export const BpaActionEtlExtractDataSchema = BpaActionBaseSchema.extend({
  type: z.literal('etl_extract_data'),
  data: z.object({
    source: z.enum(['ticket', 'line_item']),
    forms: z.array(z.number()),
    filter: QueryFilterSchema.nullish(), // todo: add ux for `DjangoQueryBuilder`
    filter_str: z.string().nullish(),
    fields: z.array(QueryFieldSchema),
    out_name: z.string()
  })
})

// etl_transform_data
export const BpaActionEtlTransformDataSchema = BpaActionBaseSchema.extend({
  type: z.literal('etl_transform_data'),
  data: z.object({
    sql_query: z.string(),
    out_name: z.string()
  })
})

// etl_load_data
export const BpaActionEtlLoadDataSchema = BpaActionBaseSchema.extend({
  type: z.literal('etl_load_data'),
  data: z.object({
    src_dataframe: z.string(),
    dst_type: z.enum(['clickhouse', 'excel']),
    dst_name: z.string()
  })
})

// etl_load_data
export const BpaActionEtlClickhouseCommandSchema = BpaActionBaseSchema.extend({
  type: z.literal('etl_clickhouse_command'),
  data: z.object({
    command_sql: z.string()
  })
})

export const FunctionCallBpaFnSchema = z.object({
  path: z.string(),
  display_name: z.string(),
  group: z.string(),
  info: z.string(),
  modes: z.enum(['sync', 'pipeline', 'scheduled'])
})

export const BpaActionSchema = z.union([
  BpaActionSetVariableSchema,
  BpaActionSetTicketFieldSchema,
  BpaActionSetTicketPropertySchema,
  BpaActionSetHiddenReferenceSchema,
  BpaActionCopyTicketSchema,
  BpaActionSyncLineItemSchema,
  BpaActionAddLineItemSchema,
  BpaActionUpdateLineItemSchema,
  BpaActionRemoveLineItemSchema,
  BpaActionReOrderLineItemsSchema,
  BpaActionSyncTeamMemberSchema,
  BpaActionAddTeamMemberSchema,
  BpaActionRemoveTeamMemberSchema,
  BpaActionSyncCustomRecordSchema,
  BpaActionSyncFromIntakeTicketSchema,
  BpaActionSendCustomEmailSchema,
  BpaActionEmailCustomerSignatureSchema,
  BpaActionEmailVendorAccessSchema,
  BpaActionSendEmailSchema,
  BpaActionSubmitToAccountingSchema,
  BpaActionSyncHttpSchema,
  BpaActionAsyncHttpSchema,
  BpaActionFunctionCallSchema,
  BpaActionSyncTicketSubscribersSchema,
  BpaActionAddTicketSubscriberSchema,
  BpaActionRemoveTicketSubscriberSchema,
  BpaActionAttachPrintedTicketSchema,
  BpaActionEtlExtractDataSchema,
  BpaActionEtlTransformDataSchema,
  BpaActionEtlLoadDataSchema,
  BpaActionEtlClickhouseCommandSchema
])

/**
 * Types
 */

export type BpaActionType = z.infer<typeof BpaActionTypeSchema>
export type BpaActionSetVariable = z.infer<typeof BpaActionSetVariableSchema>
export type BpaActionSetTicketField = z.infer<typeof BpaActionSetTicketFieldSchema>
export type BpaActionSetTicketProperty = z.infer<typeof BpaActionSetTicketPropertySchema>
export type BpaActionSetHiddenReference = z.infer<typeof BpaActionSetHiddenReferenceSchema>
export type BpaActionCopyTicket = z.infer<typeof BpaActionCopyTicketSchema>
export type BpaActionSyncLineItem = z.infer<typeof BpaActionSyncLineItemSchema>
export type BpaActionAddLineItem = z.infer<typeof BpaActionAddLineItemSchema>
export type BpaActionUpdateLineItem = z.infer<typeof BpaActionUpdateLineItemSchema>
export type BpaActionRemoveLineItem = z.infer<typeof BpaActionRemoveLineItemSchema>
export type BpaActionReOrderLineItems = z.infer<typeof BpaActionReOrderLineItemsSchema>
export type BpaActionSyncTeamMember = z.infer<typeof BpaActionSyncTeamMemberSchema>
export type BpaActionAddTeamMember = z.infer<typeof BpaActionAddTeamMemberSchema>
export type BpaActionRemoveTeamMember = z.infer<typeof BpaActionRemoveTeamMemberSchema>
export type BpaActionSyncCustomRecord = z.infer<typeof BpaActionSyncCustomRecordSchema>
export type BpaActionSyncFromIntakeTicket = z.infer<typeof BpaActionSyncFromIntakeTicketSchema>
export type BpaActionSendCustomEmail = z.infer<typeof BpaActionSendCustomEmailSchema>
export type BpaActionEmailCustomerSignature = z.infer<typeof BpaActionEmailCustomerSignatureSchema>
export type BpaActionEmailVendorAccess = z.infer<typeof BpaActionEmailVendorAccessSchema>
export type BpaActionSubmitToAccounting = z.infer<typeof BpaActionSubmitToAccountingSchema>
export type BpaActionSendEmail = z.infer<typeof BpaActionSendEmailSchema>
export type BpaActionSyncHttp = z.infer<typeof BpaActionSyncHttpSchema>
export type BpaActionAttachPrintedTicket = z.infer<typeof BpaActionAttachPrintedTicketSchema>
export type BpaActionAsyncHttp = z.infer<typeof BpaActionAsyncHttpSchema>
export type BpaActionFunctionCall = z.infer<typeof BpaActionFunctionCallSchema>
export type BpaEtlExtractDataAction = z.infer<typeof BpaActionEtlExtractDataSchema>
export type BpaEtlTransformDataAction = z.infer<typeof BpaActionEtlTransformDataSchema>
export type BpaEtlLoadDataAction = z.infer<typeof BpaActionEtlLoadDataSchema>
export type BpaEtlClickhouseCommandAction = z.infer<typeof BpaActionEtlClickhouseCommandSchema>
export type BpaAction = z.infer<typeof BpaActionSchema>
export type FunctionCallBpaFn = z.infer<typeof FunctionCallBpaFnSchema>

/**
 * Constants
 */

export const BPA_ACTIONS_LIST: {
  key: string
  label: string
  modes: ('sync' | 'pipeline')[]
  options: { value: BpaActionType; label: string; entity: string | null }[]
}[] = [
  {
    key: 'generic_actions',
    label: 'Generic Actions',
    modes: ['sync', 'pipeline'],
    options: [
      { value: 'set_variable', label: 'Set Variable', entity: null },
      { value: 'sync_custom_record', label: 'Sync Custom Record', entity: null },
      { value: 'send_custom_email', label: 'Send Custom Email', entity: null },
      { value: 'send_email', label: 'Send Email', entity: null },
      { value: 'sync_http', label: 'Sync HTTP', entity: null },
      { value: 'async_http', label: 'Async HTTP', entity: null },
      { value: 'function_call', label: 'Function Call', entity: null }
    ]
  },
  {
    key: 'ticket_actions',
    label: 'Ticket Actions',
    modes: ['sync'],
    options: [
      { value: 'set_ticket_field', label: 'Set Ticket Field', entity: 'ticket' },
      { value: 'set_ticket_property', label: 'Set Property', entity: 'properties' },
      { value: 'set_hidden_reference', label: 'Set Hidden References', entity: 'ticket' },
      { value: 'copy_ticket', label: 'Copy Ticket', entity: 'ticket' },
      { value: 'sync_line_item', label: 'Sync Line Item', entity: 'line_items' },
      { value: 'add_line_item', label: 'Add Line Item', entity: 'line_items' },
      { value: 'update_line_item', label: 'Update Line Item', entity: 'line_items' },
      { value: 'remove_line_item', label: 'Remove Line Item', entity: 'line_items' },
      { value: 'reorder_line_items', label: 'Re-Order Line Items', entity: null },
      { value: 'sync_team_member', label: 'Sync Team Member', entity: null },
      { value: 'add_team_member', label: 'Add Team Members', entity: null },
      { value: 'remove_team_member', label: 'Remove Team Members', entity: null },
      { value: 'add_ticket_subscriber', label: 'Add Ticket Subscribers', entity: null },
      { value: 'remove_ticket_subscriber', label: 'Remove Ticket Subscribers', entity: null },
      { value: 'sync_ticket_subscribers', label: 'Sync Ticket Subscriber', entity: null },
      { value: 'sync_from_intake_ticket', label: 'Sync From Intake Ticket', entity: null },
      { value: 'email_customer_signature', label: 'Email Customer Signature', entity: null },
      { value: 'email_vendor_access', label: 'Email Vendor Access', entity: null },
      { value: 'submit_to_accounting', label: 'Submit to Accounting', entity: null },
      { value: 'attach_printed_ticket', label: 'Attach Printed Ticket', entity: null }
    ]
  },
  {
    key: 'etl_actions',
    label: 'ETL Actions',
    modes: ['pipeline'],
    options: [
      { value: 'etl_extract_data', label: 'ETL Extract Data', entity: null },
      { value: 'etl_transform_data', label: 'ETL Transform Data', entity: null },
      { value: 'etl_load_data', label: 'ETL Load Data', entity: null },
      { value: 'etl_clickhouse_command', label: 'ETL Clickhouse Command', entity: null }
    ]
  }
]
